import React, { useContext, useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import AOS from 'aos';
import GlobalContext from '../context/GlobalContext';
import { Helmet } from 'react-helmet';
import { subscriptionWizardFiles } from '../constants';

export const Layout = ({ children, path }) => {
  const { isSticky } = useContext(GlobalContext);
  const [pagesWithShadow] = useState(['/terminos-y-condiciones/', '/politicas-de-privacidad/']);

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <link
          href="https://cdn.zenrise.io/libraries/js/sdk/zenrise-sdk-latest.js"
          rel="preload"
          as="script"
        ></link>

        <script 
          defer 
          type="module" 
          src="https://embeddables.p.mbirdcdn.net/v1/web-components.es.js"
        ></script>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {subscriptionWizardFiles.map(file => (
          <link key={file.src} rel="preload" as={file.type} href={file.src} />
        ))}
      </Helmet>

      <Header withShadow={pagesWithShadow.includes(path)}></Header>
      <div style={{ overflowX: 'hidden' }}>
        <main style={{ marginTop: isSticky ? '-80px' : '0' }}>{children}</main>
        <Footer></Footer>
        <messagebird-chat 
          project-id="72165c77-bca7-4194-9916-495aa14e57a0" 
          workspace-id="227fac04-11e4-4d99-b886-bdaa432faab9"
        ></messagebird-chat>
      </div>
    </>
  );
};
